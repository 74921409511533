@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.App {
  text-align: center;
  background:radial-gradient(#009900, #005500);
  height: 100vh;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  overflow: hidden;
  touch-action: none;
  position: fixed;
  width: 100%;
  height: 100vh;
}

* {
  font-family: 'Merriweather', serif;
}

.clickable {
  cursor: pointer;
}

.nameFormBox {
  width: 50%;
  min-height: 350px;
  height: 50vh;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: flex-start;
  background-color: #eee;
  border-radius: 20px;
  box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.5);
  color: black;
  padding: 20px;
}


.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10%;
}

.button {
  /* margin: 10px 100px; */
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: inherit;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button:disabled {
  border-color: #666;
  cursor: auto;
}

.nameForm {
  width: 100%;
}

input {
  margin-left: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.stats {
  margin: 20px;
}

.logo {
  color: red;
  font-size: 500%;
  text-align: center;
  width: 100%;
  font-family: 'Engagement', cursive;
  margin-top: 30px;
}

.imgLogo {
    height: 15vh;
    max-height: 200px;
    max-width: 80%;
}

.attributions {
  width: 100%;
  text-align: center;
}

.createGame:hover:not(:disabled){
  /* background-color: rgb(46, 167, 46); */
  border-color: rgb(14, 136, 24);
  color: rgb(14, 136, 24);
}

.joinGame:hover:not(:disabled) {
  /* background-color: rgb(53, 89, 250); */
  border-color: rgb(16, 43, 161);
  color: rgb(16, 43, 161);
}

.cancelButton:hover:not(:disabled) {
  /* background-color: rgb(53, 89, 250); */
  border-color: red; 
  color: red;
}

.codeForm {
  width: 100%;
  margin-top: auto;
}

.dialogBox {
  width: 40%;
  max-height: 25vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 20;
}

.relAnchor {
  height: 100%;
  width: 100%;
  position: relative;
}

.topBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  /* background-image: url("fleur-de-lys-banner.png"); */
  /* background-image: url("new-year-banner.png"); */
  background-size: auto 12vh;
  background-repeat: repeat-x;
}

.messageTitleBar {
  width: 100%;
  height: 50px;
  background-color: #999;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}

.bodySection {
  padding: 20px;
  color: black;
  text-align: left;
}

.buttonSection {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  position: absolute;
  bottom: 20px;
}
