@import url(https://fonts.googleapis.com/css2?family=Merriweather&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background:radial-gradient(#009900, #005500);
  height: 100vh;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  overflow: hidden;
  touch-action: none;
  position: fixed;
  width: 100%;
  height: 100vh;
}

* {
  font-family: 'Merriweather', serif;
}

.clickable {
  cursor: pointer;
}

.nameFormBox {
  width: 50%;
  min-height: 350px;
  height: 50vh;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: flex-start;
  background-color: #eee;
  border-radius: 20px;
  box-shadow: 5px 5px 16px 0 rgba(0, 0, 0, 0.5);
  color: black;
  padding: 20px;
}


.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10%;
}

.button {
  /* margin: 10px 100px; */
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 5px;
  background-color: inherit;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button:disabled {
  border-color: #666;
  cursor: auto;
}

.nameForm {
  width: 100%;
}

input {
  margin-left: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.stats {
  margin: 20px;
}

.logo {
  color: red;
  font-size: 500%;
  text-align: center;
  width: 100%;
  font-family: 'Engagement', cursive;
  margin-top: 30px;
}

.imgLogo {
    height: 15vh;
    max-height: 200px;
    max-width: 80%;
}

.attributions {
  width: 100%;
  text-align: center;
}

.createGame:hover:not(:disabled){
  /* background-color: rgb(46, 167, 46); */
  border-color: rgb(14, 136, 24);
  color: rgb(14, 136, 24);
}

.joinGame:hover:not(:disabled) {
  /* background-color: rgb(53, 89, 250); */
  border-color: rgb(16, 43, 161);
  color: rgb(16, 43, 161);
}

.cancelButton:hover:not(:disabled) {
  /* background-color: rgb(53, 89, 250); */
  border-color: red; 
  color: red;
}

.codeForm {
  width: 100%;
  margin-top: auto;
}

.dialogBox {
  width: 40%;
  max-height: 25vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 20;
}

.relAnchor {
  height: 100%;
  width: 100%;
  position: relative;
}

.topBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  /* background-image: url("fleur-de-lys-banner.png"); */
  /* background-image: url("new-year-banner.png"); */
  background-size: auto 12vh;
  background-repeat: repeat-x;
}

.messageTitleBar {
  width: 100%;
  height: 50px;
  background-color: #999;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}

.bodySection {
  padding: 20px;
  color: black;
  text-align: left;
}

.buttonSection {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  position: absolute;
  bottom: 20px;
}


.cardWrapper {
    display: flex;
    align-content: center;
    height: 100%;
    flex-grow: 0;
    position: absolute;
    -webkit-appearance: none !important;
}

.card {
    border-radius: 12px;
    /* -webkit-appearance: none !important; */
    /* -webkit-box-shadow: -2px 0 8px 0 rgba(0, 0, 0, 0.4) !important; */
    /* box-shadow: -2px 0px 8px 0 rgba(0, 0, 0, 0.4); */
    -webkit-filter: drop-shadow(-2px 0 8px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(-2px 0 8px rgba(0, 0, 0, 0.4));
    color: black;
    transition: box-shadow 0.2s ease-in, -webkit-transform 0.2s ease-in;
    transition: box-shadow 0.2s ease-in, transform 0.2s ease-in;
    transition: box-shadow 0.2s ease-in, transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
    max-height: 100%;
    height: auto;
    margin: auto 0;
    width: 100%;
	will-change: filter, left, top;
}

.card.interactive:hover:not(.card.active){
    box-shadow: -2px 0px 16px 0 rgba(0, 0, 0, 0.4);
    -webkit-transform:  translateY(-10px);
            transform:  translateY(-10px);
}

.card.active {
    box-shadow: -2px -2px 16px 0 rgba(0, 60, 255, 0.4);
    border: 2px solid rgba(0, 60, 255, 0.7);
    -webkit-transform:  translateY(-10px);
            transform:  translateY(-10px);
}

.card.highlighted:not(.active) {
    border: 2px solid gold;
}

.cardCollection {
    /* display: flex; */
    position: relative;
    /* justify-content: center; */
    /* align-items: center; */
    /* flex-flow: row nowrap; */
    /* padding: 20px; */
    /* margin: 20px; */
    width: 100%;
    height: 100%;
    /* background-color: #006800; */
    border-radius: 10px;
}

.collectionWrapperBottom {
    position: absolute;
    bottom: 10px;
    width: 60%;
    height: 30vh;

    margin: 0 auto;
    left: 0;
    right: 0;
}

.collectionWrapperTop {
    position: absolute;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
    top: -10vh;
    width: 60%;
    height: 25vh;

    margin: 0px auto;
    left: 0;
    right: 0;
}
.collectionWrapperTop p {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
}

.collectionWrapperLeft {
    position: absolute;
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
    left: -10%;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 30%;
    height: 30vh;
}

.nameAndCards {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.nameWrapper {
    width: 100%;
}

.collectionWrapperBottom .nameWrapper {
    /* top: -10vh; */
}

.collectionWrapperTop .nameWrapper {
    /* top: -10vh; */
}

.collectionWrapperRight {
    position: absolute;
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
    right: -10%;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 30%;
    height: 30vh;
}

.playCardButton {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    width: 20%;
    margin: 0 auto;
    z-index: 10;
    background-color: white;
    border-radius: 500px;
    border: 2px solid black;
    padding: 5px 10px;
    text-decoration: underline;
    cursor: pointer;
}

.playCardButton:hover {
    background-color: #ccc;
}

.centerCards {
    position: absolute;
    width: 30%;
    height: 30vh;
    top: 30vh;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.centerCards > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 25%;
    height: auto;
}

.southCenterCard {
    -webkit-transform: translate(0, 30%);
            transform: translate(0, 30%);
}

.westCenterCard {
    -webkit-transform: rotateZ(90deg) translate(0, 30%);
            transform: rotateZ(90deg) translate(0, 30%);
}

.northCenterCard {
    -webkit-transform: rotateZ(180deg) translate(0, 30%);
            transform: rotateZ(180deg) translate(0, 30%);
}

.eastCenterCard {
    -webkit-transform: rotateZ(-90deg) translate(0, 30%);
            transform: rotateZ(-90deg) translate(0, 30%);
}

.playerName {
    margin: 7px;
    font-size: 110%;
}

.teamScore {
    margin-left: 5px;
    margin-right: 5px;
}

.nameWrapper.nextPlayer {
    border: 2px solid red;
    border-radius: 10px;
    width: -webkit-max-content;
    width: max-content;
    display: flex;
    justify-content: center;
    justify-items: center;
    margin: 3px auto;
    padding: 0 20px;
}

.wagerPanel {
    position:absolute;
    width: 60%;
    height: 50vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-transform: translateY(-10vh);
            transform: translateY(-10vh);
    overflow: auto;
    min-width: 700px;
    z-index: 1;

}

.wagerOptions {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: center;
    padding: 5px;
    overflow: auto;
}

.wagerOption {
    border-radius: 10px;
    /* background-color: rgba(0, 0, 0, 0.7); */
    /* border: 1px solid white; */
    /* color: white; */
    border: 1px solid black;
    color: black;
    text-align: center;
    padding: 5px 15px;
    margin: 5px;
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wagerOption.disabled {
    color: #999;
    border-color: #999;
    cursor: auto;
}

.wagerOption:hover:not(.disabled) {
    color: red;
    border-color: red;
    /* background-color: #ccc; */
}

.wagerOption.disabled .wagerLabelSuitRed {
    color: #999;
}

.wagerOption.disabled .wagerLabelSuitBlack {
    color: #999;
}

.wagerOptionWrapper {
    display: flex;
    justify-content: center;
    height:100%;
    align-content: center;
    overflow: auto;
}

.wagerLabelSuitBlack {
    font-size: 120%;
    color: #000;
}

.wagerLabelSuitRed {
    font-size: 120%;
    color: red;
}

.wagerValue {
    font-size: 80%;
    margin-left: 5px;
}

.scoreCardRight {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 18%;
    height: 25vh;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    max-height: 250px;
    /* overflow: scroll; */
}

.scoreCardLeft {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 18%;
    height: 25vh;
    /* min-height: 200px; */
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    max-height: 250px;
    /* overflow: scroll; */
}



.scores {
    color: black;
    background-color: #fff;
    /* height: 82%; */
    height: 100%;
    overflow: visible;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content:space-around;
    /* align-content: flex-start; */
    overflow: auto;
}





.playerNames {
    border-bottom: 1px solid black;
}

.titleBar {
    background-color: #ccc;
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 18%;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
}

.playerWager {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.suitChooser {
    width: 20%;
    height: 20vh;
    display: grid;
    grid-template-columns: auto auto;
}

.suitOption {
    font-size: 170%;
    border-radius: 10px;
    border: 1px solid white;
    color: white;
    text-align: center;
    padding: 5px 15px;
    margin: 5px;
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blackSuit {
    color: #444;
    /* border-color: #444; */
}

.redSuit {
    color: red;
    /* border-color: red; */
}

.gameCodeBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 15%;
    height: auto;
    border-radius: 0 0 5px 5px;
    background-color: white;
    color: black;
}

.gameCodeTitle {
    font-size: 80%;
}

.gameCode {
    font-size: 150%;
}

.spectatorMark {
    color: red;
    font-size: 150%;
    position: absolute;
    right: 2pt;
    top: 0;
}

.spectatorCount {
    color: white;
    font-size: 120%;
    position: absolute;
    right: 2pt;
    top: 0;
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .scoreCardLeft {
        bottom: 8vh;
    }

    .scoreCardRight {
        bottom: 8vh;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .scoreCardLeft {
        bottom: 6vh;
    }

    .scoreCardRight {
        bottom: 6vh;
    }

    .wagerPanel {
        width: 80%;
    }
}

